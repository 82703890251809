import Big from 'big.js';

export function getCollectionPath(resource_path) {
  return resource_path.slice(0, resource_path.lastIndexOf('/'));
}

export function handleServerNetworkError(error) {
  if (error instanceof TypeError) {
    showErrorFlash(`Hálózati hiba, szerver nem elérhető: ${error.message}`);
  }
  else {
    showErrorFlash('Szerveroldali hiba történt!');
  }
}

export function handleActiveModelError(item, timeout = 8000) {
  switch(item.status) {
  case 'not_found':
    showErrorFlash('Tétel nem található!');
    return true;
  case 'unprocessable_entity':
    showErrorFlash(Object.entries(item.errors).map(([, message]) => message.join('\n')).join('\n'), timeout);
    return true;
  default:
    return false;
  }
}

export function formatShortDate(date) {
  if (date == null) {
    return null;
  }
  if (typeof date === 'string') {
    if (date === '') {
      return null;
    }
    date = new Date(date);
  }
  const dateFormatter = Intl.DateTimeFormat('hu-HU', { year: '2-digit', month: '2-digit', day: '2-digit' });
  return dateFormatter.formatToParts(date)
    .filter(datePart => datePart.type !== 'literal')
    .map(datePart => datePart.value)
    .join('-');
}
export function slideUp(timeout) {
  let flash = document.querySelector('#oms-flash');
  if (flash) {
    setTimeout(() => flash.style.maxHeight = '0px', timeout);
  }
}

export function slideDown(timeout) {
  let flash = document.querySelector('#oms-flash');
  if (flash) {
    setTimeout(() => flash.style.maxHeight = '30px', timeout);
  }
}

export function showSuccessFlash({
  message,
  timeout = 4000,
  discard_new_flash = true
}) {
  let success = createElementFromString(`<div class="oms-flash-success">${message}</div>`);

  let successContainer = document.querySelector('#oms-flash .oms-flash-success');
  if (successContainer) {
    successContainer.remove();
  }
  let errorContainer = document.querySelector('#oms-flash .oms-flash-error');
  if (errorContainer) {
    errorContainer.remove();
  }

  document.querySelector('#oms-flash').appendChild(success);

  slideDown(0);
  slideUp(timeout);

  if (discard_new_flash) {
    document.cookie = 'oms-discard-flash=success;max-age=360;';
  }
}

export function swapElements(obj1, obj2) {
  // create marker element and insert it where obj1 is
  const temp = document.createElement('div');
  obj1.parentNode.insertBefore(temp, obj1);

  // move obj1 to right before obj2
  obj2.parentNode.insertBefore(obj1, obj2);

  // move obj2 to right before where obj1 used to be
  temp.parentNode.insertBefore(obj2, temp);

  // remove temporary marker node
  temp.parentNode.removeChild(temp);
}

export function showErrorFlash(message, timeout = 8000) {
  let error = createElementFromString(`<div class="oms-flash-error">${message}</div>`);

  let successContainer = document.querySelector('#oms-flash .oms-flash-success');
  if (successContainer) {
    successContainer.remove();
  }
  let errorContainer = document.querySelector('#oms-flash .oms-flash-error');
  if (errorContainer) {
    errorContainer.remove();
  }

  document.querySelector('#oms-flash').appendChild(error);

  slideDown(0);
  slideUp(timeout);
}

export function showPromptOnClick(selector_or_elements, controlText) {
  let el;
  if (typeof selector_or_elements === 'string') {
    el = document.querySelectorAll(selector_or_elements);
  }
  else {
    el = selector_or_elements;
  }

  el.forEach(item => item.addEventListener('click', event => {
    const value = prompt(`Írd be a '${controlText}' szót az alábbi mezőbe: `);

    if (value == null || value != controlText) {
      event.stopPropagation();
      event.preventDefault();
    }
  }));
}

export function showConfirmOnClick(selector_or_elements, confirmText, parent = null) {
  if (parent === null) {
    let el;
    if (typeof selector_or_elements === 'string') {
      el = document.querySelectorAll(selector_or_elements);
    } else {
      el = selector_or_elements;
    }

    el.forEach(item => item.addEventListener('click', event => {
      if (!confirm(confirmText)) {
        event.stopPropagation();
        event.preventDefault();
      }
    }));
  }
  else {
    parent.addEventListener('click', event => {
      if (event.target.matches(selector_or_elements)) {
        if (!confirm(confirmText)) {
          event.stopPropagation();
          event.preventDefault();
        }
      }
    });
  }
}

export function htmlEntities(string) {
  return string.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}

export function decodeHtmlEntities(string) {
  let temp = createElementFromString('<div/>').innerHTML(string);
  return temp.textContent;
}

export function dictToQueryParamString(dict) {
  let searchParams = new URLSearchParams();

  for (let [key, value] of Object.entries(dict)) {
    const resolvedValue = (typeof value === 'function') ? value() : value;
    if (Array.isArray(resolvedValue)) {
      for (const item of resolvedValue) {
        searchParams.append(`${key}[]`, item);
      }
    }
    else {
      searchParams.append(key, resolvedValue);
    }
  }
  return searchParams.toString();
}

export function omsFetch({
  serviceUrl,
  method = 'GET',
  cache = 'no-cache',
  params = {},
  contentType = 'application/json',
  accepts = 'application/json;charset=utf-8'
}) {
  const csrfSelector = document.querySelector('meta[name="csrf-token"]');
  const csrfToken = csrfSelector ? csrfSelector.content : null;

  let headers = (method !== 'GET' && csrfToken) ? { 'X-CSRF-Token': csrfToken } : {};
  headers['Accept'] = accepts;
  let resolvedUrl = typeof serviceUrl === 'function' ? serviceUrl() : serviceUrl;

  if (method === 'GET' || method === 'DELETE') {
    const paramString = dictToQueryParamString(params);
    if (paramString) {
      resolvedUrl = `${resolvedUrl}?${paramString}`;
    }
  }

  let body = null;
  // if the params is not empty and the method is POST, it will be sent in the body, so set the Content-Type header
  if ((method === 'POST' || method === 'PATCH') && params !== {}) {
    if (contentType) {
      headers['Content-Type'] = contentType;
    }
    switch(contentType) {
    case 'application/json':
      body = JSON.stringify(params);
      break;
    default:
      body = params;
      break;
    }
  }

  return fetch(resolvedUrl, {
    method: method,
    cache: cache,
    headers: headers,
    body: body
  }).then(response => {
    if (!response.ok) {
      throw response;
    }
    if (accepts === 'application/json;charset=utf-8') {
      return response.json();
    }
    return response.text();
  });
}

export function parseBigWithUnit(text) {
  return Big(text.replace(/[\sa-zA-Z\u00C0-\u024F\u1E00-\u1EFF]/g, '').replace(',', '.'));
}

export function parseUnit(text) {
  return text.trim().substring(text.trim().lastIndexOf(' ') + 1);
}

export function monetaryToString(value, currency) {
  const value_str = value.toLocaleString('hu-HU', { stye: 'currency', currency: currency });
  if (currency == null) {
    return value_str;
  }
  return `${value_str} ${currency}`;
}

export function throttle(callback, timeLimit) {
  let wait = false;
  return function () {
    if (!wait) {
      callback.call();
      wait = true;
      setTimeout(function () {
        wait = false;
      }, timeLimit);
    }
  };
}

export function createElementFromString(string) {
  let tempElement = document.createElement('template');
  tempElement.innerHTML = string;
  return tempElement.content.childElementCount === 1 ? tempElement.content.firstElementChild : tempElement.content.children;
}

if (typeof HTMLElement.previousSiblingWithMatch === 'undefined') {
  HTMLElement.prototype.previousSiblingWithMatch = function(selector) {
    let sibling = this.previousElementSibling;
    while (sibling) {
      if (sibling.matches(selector)) return sibling;
      sibling = sibling.previousElementSibling;
    }
  };
}

if (typeof HTMLElement.nextSiblingWithMatch === 'undefined') {
  HTMLElement.prototype.nextSiblingWithMatch = function(selector) {
    let sibling = this.nextElementSibling;
    while (sibling) {
      if (sibling.matches(selector)) return sibling;
      sibling = sibling.nextElementSibling;
    }
  };
}
