import { omsAutoComplete } from 'autocomplete';

export default class {
  constructor({ selector, serviceURL, onSelect }) {
    this.field = selector;
    this.serviceURL = serviceURL;
    this.onSelect = onSelect;

    this.initialize();
  }

  clear() {
    document.querySelector(this.field).value = '';
    this.onSelect({ value: '' });
  }

  initialize() {

    let self = this;

    omsAutoComplete({
      selector: this.field,
      serviceUrl: this.serviceURL,
      onSelect: (suggestion) => {
        if (self.onSelect) {
          self.onSelect(suggestion);
        }
        else {
          location.assign(suggestion.url);
        }
      }
    });
    document.querySelector(this.field).addEventListener('keypress', event => {
      if (event.key === 'Enter' && this.onSelect) {
        this.onSelect({ value: event.target.value });
      }
    });
  }
}