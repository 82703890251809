import './loader.js';
import 'ckeditor/ckeditor';

window.CKEDITOR.config.customConfig = 'config.js';

// disable line break after <br> tag, for Prawn
window.CKEDITOR.on('instanceReady', function(event) {
  let writer = event.editor.dataProcessor.writer;
  writer.setRules('br', {
    indent : false,
    breakBeforeOpen : false,
    breakAfterOpen : false,
    breakBeforeClose : false,
    breakAfterClose : false
  });

  // replace the newline with br, because CKEditor says fuck you and strips all of them
  let data = event.editor.element.$.value;
  let replacedData = data.replace(/(?:\r\n|\r|\n)/g, '<br />');
  event.editor.setData(replacedData);

});

// destroy and remake CKEDITOR instances on turbolinks load
document.addEventListener('turbolinks:load', function() {
  if (typeof window.CKEDITOR !== 'undefined') {
    for (let instanceName in window.CKEDITOR.instances) {
      window.CKEDITOR.instances[instanceName].destroy();
    }
  }

  document.querySelectorAll('.ckeditor').forEach(editor => {
    window.CKEDITOR.replace(editor.id);
  });
});
