import RemoteSearch from 'remote_search';
import { createElementFromString } from './utils';

function reloadTableOrWindow(table) {
  if (table) {
    table.reload({ newFilter: true });
  }
  else {
    location.replace(location.pathname);
  }
}

export default class Filters {
  static start() {
    document.addEventListener('turbolinks:load', () => {
      this._addClearButtons();
      this._checkBoxListFilter();
      this._dateFilters();
      this._selectBoxFilters();
      this._remoteSearchFilter();
      this._cancelFilter();
    });
  }
  static setTable(table) {
    this.constructor.table = table;
  }
  static _addClearButtons() {
    const calcPosition = input => {
      const inputPos = input.getBoundingClientRect();
      const offset = input.offsetParent.getBoundingClientRect();
      const clearButton = input.nextSibling;
      const cbPos = clearButton.getBoundingClientRect();

      clearButton.style.position = 'absolute';
      clearButton.style.left = `${inputPos.left - offset.left + inputPos.width - cbPos.width}px`;
      clearButton.style.top = `${inputPos.top - offset.top + (inputPos.height - cbPos.height) / 2}px`;
    };
    const resizeObserver = new ResizeObserver(resizes => {
      for (const resize of resizes) {
        calcPosition(resize.target);
      }
    });

    const mutationObserver = new MutationObserver(() => {
      document.querySelectorAll('.oms-input-with-clear').forEach(input => calcPosition(input));
    });

    document.querySelectorAll('.oms-input-with-clear').forEach(input => {
      const clearButton = createElementFromString(`<span class="oms-input-clear-button" data-for="${input.id}"></span>`);
      input.insertAdjacentElement('afterend', clearButton);

      calcPosition(input);

      resizeObserver.observe(input);

      clearButton.addEventListener('click', () => {
        if (input._flatpickr) {
          input._flatpickr.clear();
        }
        else if (input._remoteSearch) {
          input._remoteSearch.clear();
        }
        else {
          input.value = '';
        }
      });
    });

    const filterUI = document.querySelector('#oms-filter');
    if (filterUI) {
      const config = { attributes: true, childList: true, subtree: true };
      mutationObserver.observe(filterUI, config);
    }
  }

  static _checkBoxListFilter() {
    document.querySelectorAll('.oms-filter-checkbox_list')
      .forEach(input => {
        const option_name = input.id;
        const self = this;
        const checkboxes = input.querySelectorAll('input[type="checkbox"]');
        checkboxes.forEach(checkbox => checkbox.addEventListener('click', function(event) {
          const id = this.dataset.id;

          if (!window.Options.hasOption(option_name)) {
            window.Options.setOption(option_name, []);
          }
          const currentOption = window.Options.getOption(option_name);

          // if shift is pressed, we want to add all other ids to the array
          if (event.shiftKey) {
            // first, clear current ids
            //currentOption.splice(0, currentOption.length);
            const toFilterOut = [];
            // then add the other, except the current one
            // also make sure the checkbox visually shows the correct items checked
            checkboxes.forEach(checkbox => {
              if (checkbox.id === event.target.id) {
                checkbox.checked = true;
              }
              else {
                toFilterOut.push(checkbox.dataset.id);
                checkbox.checked = false;
              }
            });
            currentOption.splice(0, toFilterOut.length, ...toFilterOut);
          }
          else {
            if (event.target.checked) {
              currentOption.splice(currentOption.indexOf(id), 1);
            } else {
              currentOption.push(id);
            }
          }

          reloadTableOrWindow(self.constructor.table);
        }));
      });
  }
  static _dateFilters() {
    document.querySelectorAll('.oms-date-filter').forEach(input => {
      const self = this;
      input.addEventListener('change', function () {
        window.Options.setOption(input.name, this.value);

        reloadTableOrWindow(self.constructor.table);
      });
    });
  }
  static _selectBoxFilters() {
    document.querySelectorAll('.oms-select-filter').forEach(input => {
      const self = this;
      input.addEventListener('change', function () {
        window.Options.setOption(input.name, this.querySelector('option:checked').value);

        reloadTableOrWindow(self.constructor.table);
      });
    });
  }
  static _remoteSearchFilter() {
    document.querySelectorAll('.oms-search-filter').forEach(input => {
      input['_remoteSearch'] = new RemoteSearch({
        selector: `#${input.id}`,
        serviceURL: input.dataset.searchUrl,
        onSelect: data => {
          window.Options.setOption(input.name, data.value);
          reloadTableOrWindow(this.constructor.table);
        }
      });
    });
  }
  static _cancelFilter() {
    document.querySelectorAll('.oms-filter-cancel a').forEach(input => {
      const user_option = input.id.replace('_cancel', '');
      input.addEventListener('click', event => {
        event.preventDefault();
        window.Options.setOption(user_option, '');

        reloadTableOrWindow(this.constructor.table);
      });
    });
  }
}

Filters.table = null;