export default class {
  constructor(user, options) {
    this.user = user;
    this.options = {};

    let that = this;

    Object.keys(options).forEach(function(key) {
      // if it is an array, we make it observable to write out changes into the cookie
      if (options[key].constructor === Array) {
        that.options[key] = new Proxy(options[key], {
          apply: function(target, thisArg, argumentList) {
            that._writeCookie();
            return thisArg[target].apply(this, argumentList);
          },
          deleteProperty: function() {
            that._writeCookie();
            return true;
          },
          set: function(target, property, value) {
            target[property] = value;
            that._writeCookie();
            return true;
          }
        });
      }
      // otherwise, just store it as is
      else {
        that.options[key] = options[key];
      }
    });
  }

  _writeCookie() {
    const expires = new Date(Date.now() + 30*864e5).toUTCString();
    document.cookie = `oms_usr_${this.user}_options_v2=${encodeURIComponent(JSON.stringify(this.options))}; expires=${expires}; path=/`; 
  }

  hasOption(key) {
    return key in this.options;
  }
  getOption(key) {
    return this.options[key];
  }
  setOption(key, value) {
    let that = this;
    if (value.constructor === Array) {
      this.options[key] = new Proxy(value, {
        apply: function(target, thisArg, argumentList) {
          return thisArg[target].apply(this, argumentList);
        },
        deleteProperty: function() {
          return true;
        },
        set: function(target, property, value) {
          target[property] = value;
          that._writeCookie();
          return true;
        }
      });
    }
    else {
      this.options[key] = value;
      this._writeCookie();
    }
  }
}
