import { omsFetch, createElementFromString, showPromptOnClick } from 'utils';
import Filters from 'filters';

export default class Table {
  static init() {
    document.addEventListener('turbolinks:load', () => {
      document.querySelectorAll('.oms-pagination').forEach(pagination => {

        const tableClass = pagination.dataset.tableClass;
        if (pagination.dataset.url == null) {
          pagination['_table'] = new Table({
            tableElement: pagination.querySelector(`.${tableClass}`),
            resourceName: pagination.dataset.resource,
            paginator: pagination.querySelector('.oms-paginator'),
            fetchMode: pagination.dataset.fetchMode
          });
        }
        else {
          pagination['_table'] = new Table({
            tableElement: pagination.querySelector(`.${tableClass}`),
            resourceName: pagination.dataset.resource,
            paginator: pagination.querySelector('.oms-paginator'),
            url: pagination.dataset.url,
            fetchMode: pagination.dataset.fetchMode
          });
        }
      });
    });
  }
  static start() {
    document.querySelectorAll('.oms-pagination').forEach(pagination => {
      pagination._table._fetchBatch();
    });
  }
  constructor(
    {
      tableElement,
      resourceName,
      paginator,
      fetchSize = 10,
      fetchMode = 'increment',
      url = `/${resourceName}`
    }
  ) {
    this.table = tableElement;
    this.container = tableElement.querySelector('tbody') || tableElement;
    this.paginator = paginator;
    this.fetchMode = this.paginator == null ? 'all' : fetchMode;
    this.resourceName = resourceName;
    this.url = url;
    this.fetchSize = fetchSize;
    this.step = 0;
    this.fetchInProgress = false;
    this.rowLoadedCallbacks = [];
    this.loadFinishedCallbacks = [];
    this.filtersChangedCallbacks = [];
    this.metaRowCallbacks = [];
    this.sortDir = null;
    this.sortParam = null;
    this.reloaded = false;

    Filters.setTable(this);

    this._initPlaceholder();
    this._initSorting();
    this._initFetchMode();
  }

  refresh() {
    if (this.fetchMode === 'all') {
      this.step = 0;
    }
    this.reload({ step: this.step });
  }

  reload({ step = 0, newFilter = false }) {
    this.reloaded = true;
    if (newFilter === true) {
      const self = this;
      for (const callback of this.filtersChangedCallbacks) {
        callback(self);
      }
    }

    // first clear the existing records
    if (this.container.matches('table')) {
      //if the container is a table, don't delete the header
      while(this.container.lastChild && !(this.container.lastChild.tagName === 'THEAD')) {
        this.container.removeChild(this.container.lastChild);
      }
    }
    else {
      this.container.innerHTML = '';
    }
    this._initPlaceholder();

    // reset the step
    this.step = step;

    // then fetch the initial batch
    this._fetchBatch();
  }

  addRowLoadedCallback(callback) {
    this.rowLoadedCallbacks.push(callback);
  }

  addLoadFinishedCallback(callback) {
    this.loadFinishedCallbacks.push(callback);
  }

  addFiltersChangedCallback(callback) {
    this.filtersChangedCallbacks.push(callback);
  }

  addMetaRowCallback(callback) {
    this.metaRowCallbacks.push(callback);
  }

  _fetchBatch() {
    if (!this.fetchInProgress) {
      this.fetchInProgress = true;
      const limit = this.fetchMode === 'increment' ? this.fetchSize : 50;
      const previousMode = this.fetchMode;
      this.reloaded = false;
      omsFetch({
        serviceUrl: this.url,
        params: {
          resource: this.resourceName,
          limit: limit,
          offset: limit * this.step,
          sort: this.sortParam,
          dir: this.sortDir
        },
        accepts: 'text/html;charset=utf-8'
      }).then(response => {
        this.fetchInProgress = false;
        if (previousMode !== this.fetchMode || this.reloaded) {
          // if the mode changed while a request was in progress, discard the response and do it again
          this._fetchBatch();
          return;
        }
        // return early, if we reached the end
        if (response === '') {
          if (this.fetchMode === 'increment') {
            this.paginator.querySelector('.oms-next-page').classList.add('oms-pagination-disabled');
          }
          this.refreshPlaceholder.style.display = 'none';
        }
        this._addRows(response);
        if (this.fetchMode === 'all' && response !== '') {
          this._fetchBatch();
        }
        else {
          for (const callback of this.loadFinishedCallbacks) {
            callback(this);
          }
        }
      }).catch(() => this.fetchInProgress = false);
    }
  }

  _addRows(response) {
    const addRow = row => {
      if (row.id === 'meta') {
        const self = this;
        for (const callback of this.metaRowCallbacks) {
          callback(row, self);
        }
        row.remove();
        return;
      }
      this.container.appendChild(row);
      const self = this;
      for (const callback of this.rowLoadedCallbacks) {
        callback(row, self);
      }
      showPromptOnClick(row.querySelectorAll('.oms-table-destroy'), 'törlés');
    };

    const rows = createElementFromString(response);
    if (rows.length === undefined) {
      addRow(rows);
    }
    else if (rows.length === 0 && this.fetchMode === 'increment') {
      this.container.appendChild(createElementFromString(`
        <tr class="oms-table-empty-row">
            <td colspan="${this.maxColSpan}">...</td>
        </tr>`
      ));
    }
    else {
      while(rows.length !== 0) {
        addRow(rows[0]);
      }
    }
    this.refreshPlaceholder.style.display = 'none';
    if (this.fetchMode === 'all') {
      this.step++;
    }
  }

  _initSorting() {
    const self = this;
    document.querySelectorAll('.sortable').forEach(item => item.addEventListener('click', function() {
      if (self.fetchInProgress === true) {
        return;
      }
      if (this.classList.contains('sortable-desc')) {
        self.sortDir = 'asc';
      }
      else {
        self.sortDir = 'desc';
      }
      document.querySelectorAll('.sortable').forEach(item => {
        item.classList.remove('sortable-desc');
        item.classList.remove('sortable-asc');
      });

      this.classList.add(`sortable-${self.sortDir}`);
      self.sortParam = this.id;
      self.reload({ step: 0 });
    }));
  }

  _initFetchMode() {
    const nextPage = this.paginator.querySelector('.oms-next-page');
    const prevPage = this.paginator.querySelector('.oms-previous-page');
    const allButton = this.paginator.querySelector('.oms-all');

    prevPage.addEventListener('click', () => {
      if (this.fetchMode === 'all' || this.step === 0) {
        return;
      }
      this.step = this.step - 1;
      this.reload({ step: this.step });
      nextPage.classList.remove('oms-pagination-disabled');
      if (this.step === 0) {
        prevPage.classList.add('oms-pagination-disabled');
      }
    });

    nextPage.addEventListener('click', () => {
      if (this.fetchMode === 'all') {
        return;
      }
      if (nextPage.classList.contains('oms-pagination-disabled')) {
        return;
      }
      this.step = this.step + 1;
      this.reload({ step: this.step });
      prevPage.classList.remove('oms-pagination-disabled');
    });

    allButton.addEventListener('click', event => {
      if (this.fetchMode === 'all') {
        this.fetchMode = 'increment';
        event.target.classList.remove('oms-pagination-active');
        prevPage.classList.remove('oms-pagination-disabled');
        nextPage.classList.remove('oms-pagination-disabled');
      }
      else {
        this.fetchMode = 'all';
        event.target.classList.add('oms-pagination-active');
        prevPage.classList.add('oms-pagination-disabled');
        nextPage.classList.add('oms-pagination-disabled');
      }
      this.reload({ step: 0 });
    });
  }

  _initPlaceholder() {
    if (this.maxColSpan == null) {
      this.maxColSpan = 0;
      for (const cell of this.table.querySelector('thead tr').children) {
        this.maxColSpan += cell.colSpan || 1;
      }
    }

    this.refreshPlaceholder = this.container.appendChild(
      createElementFromString(`
        <tr class="oms-table-refresh-placeholder">
          <td colspan="${this.maxColSpan}">
            <span></span>
          </td>
        </tr>
      `)
    );
    this.refreshPlaceholder.style.width = this.container.offsetWidth;
  }
}