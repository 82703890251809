import { showErrorFlash, omsFetch, createElementFromString, handleServerNetworkError } from 'utils';
import NProgress from 'nprogress';
import { omsAutoComplete } from 'autocomplete';

export function autoRefresh() {
  let time = new Date().getTime();
  document.body.addEventListener('mousemove keypress', () => time = new Date().getTime());

  function reload() {
    // if it's more than 2 minutes since the last activity
    if (new Date().getTime() - time >= 120000) {
      window.location.reload(true);
    }
    else {
      // check every 10 seconds
      setTimeout(reload, 10000);
    }
  }

  // check every 10 seconds
  setTimeout(reload, 10000);
}

export function toggleVisibility() {
  document.querySelectorAll('.oms-toggle-item')
    .forEach(item => item.addEventListener('click', event =>
    {
      const to_toggle = document.querySelector(event.target.dataset.toToggle);
      const default_display = event.target.dataset.defaultDisplay;
      if (to_toggle.style.display === 'none') {
        to_toggle.style.display = default_display;
      }
      else {
        to_toggle.style.display = 'none';
      }
    }));
}

export function dropdown() {
  const hideDropdowns = () => document.querySelectorAll('.oms-dropdown').forEach(dropdown => dropdown.style.display = 'none');

  document.querySelectorAll('.oms-dropdown').forEach(dropdown => {
    document.querySelector(`[data-dropdown="${dropdown.id}"]`)?.addEventListener('click', () => {
      event.preventDefault();
      event.stopPropagation();
      hideDropdowns();
      const parentRect = event.currentTarget.getBoundingClientRect();
      dropdown.style.display = 'inline-block';
      dropdown.style.top = parentRect.bottom;
      dropdown.style.left = `${parentRect.left}px`;
    });
  });
  // hide the dropdown if clicked anywhere else
  // because the click handler on the dropdown stops event propagation, this will not run if clicked on the dropdown
  document.addEventListener('click', hideDropdowns);
}

// UPDATE THE PREMISE SELECTIONS AFTER SELECTING THE CLERK
export function updatePremises(clerkID, premise_selector, selected_id = null) {
  // return it, so the promise functions (done, fail, etc.) can be used
  return omsFetch({
    serviceUrl: '/premises',
    params: { query: clerkID }
  }).then(data => {
    let premise_select = document.querySelector(premise_selector);
    premise_select.innerHTML = '';
    data.suggestions.forEach(item => {
      let selected = false;
      if (selected_id === null) {
        if (item.data === data.main_premise) {
          selected = true;
        }
      }
      else {
        if (item.data === selected_id) {
          selected = true;
        }
      }

      premise_select.appendChild(new Option(item.value, item.data, false, selected));
    });
  }).catch(error => handleServerNetworkError(error));
}

// CLERK AUTOCOMPLETE
export function clerkAutoComplete(onSelect, relationship, input = '#clerk') {
  omsAutoComplete({
    selector: input,
    serviceUrl: '/clerks/search',
    onSelect: onSelect,
    params: {
      relationship: relationship
    }
  });
}

// SERVER-SIDE SORT
export function sortTable() {
  let params = getQueryParameters();
  let currentSortedHeader = params.sort;
  let currentDir = params.dir;

  if (currentDir && currentSortedHeader) {
    document.querySelector('#' + currentSortedHeader).classList.add('sortable-' + currentDir);
  }

  document.querySelectorAll('.sortable').forEach(item => item.addEventListener('click', function() {
    let dir = 'desc';
    if (this.classList.contains('sortable-desc')) {
      dir = 'asc';
    }

    let params = {
      'sort': this.id,
      'dir': dir
    };
    let esc = encodeURIComponent;
    let query = Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&');

    // clear the existing params
    // like previous searches or page number
    let urlWithSortQuery = window.location.href.split('?')[0] + '?' + query;

    window.location.replace(urlWithSortQuery);
  }));
}

// NPROGRESS
export function progress() {

  document.addEventListener('turbolinks:request-start', () => NProgress.start());
  document.addEventListener('turbolinks:load', () => NProgress.done());
  document.addEventListener('turbolinks:request-end', () => NProgress.remove());
}

// CHANGE THE TEXTAREA CONTENT BASED ON THE SELECT BOX
export function templateChange(selectBox, textArea) {
  document.querySelector(selectBox).addEventListener('change', function() {

    if (this.value) {
      omsFetch({
        serviceUrl: `/templates/${this.value}`
      }).then(data => window.CKEDITOR.instances[textArea].setData(data.template.content))
        .catch(error => handleServerNetworkError(error));
    }
  });
}
// ADD THE SELECTED CHECKBOX VALUES AS QUERY PARAMS TO THE SPECIFIED LINKS
export function checkboxSelectionToQueryParams(checkbox_selector, param_name, link_selector) {
  document.querySelectorAll(link_selector).forEach(link => link.addEventListener('click', event => {
    let params = new URLSearchParams();
    document.querySelectorAll(checkbox_selector).forEach(item => {
      if (item.checked) {
        params.append(param_name + '[]', item.value);
      }
    });
    let queryString = params.toString();

    if (queryString) {
      if (/[?&].+=/.test(event.target.href)) {
        event.target.href = `${event.target.href}&${queryString}`;
      }
      else {
        event.target.href = `${event.target.href}?${queryString}`;
      }
    }
  }));
}

// ASYNC STATISTIC LOADING
export function getStats(url, container_selector, checkbox_selector) {

  let loadStats = function (){
    let container_element = document.querySelector(container_selector);
    container_element.innerHTML = '';
    container_element.appendChild(createElementFromString('<tr><td colspan="2"></td></tr>'));
    let wait_element = document.querySelector(container_selector + ' tr td');
    wait_element.innerHTML = '&nbsp;';
    wait_element.style.textAlign = 'center';
    wait_element.style.fontWeight = 'bold';
    wait_element.style.backgroundColor = 'white';
    let interval = window.setInterval(function() {
      if (wait_element.innerHTML.length > 8) 
        wait_element.innerHTML = '&nbsp;';
      else 
        wait_element.innerHTML = wait_element.innerHTML + '.';
    }, 200);

    let ids = [];
    if (checkbox_selector !== undefined) {
      ids = Array.from(document.querySelectorAll(checkbox_selector + ':checked')).map(item => item.value);
    }

    omsFetch({
      serviceUrl: url,
      params: {
        start: document.querySelector('#stat_date_start').value,
        end: document.querySelector('#stat_date_end').value,
        invoice: document.querySelector('#oms-search-invoice').value,
        invoiced_only: document.querySelector('#oms-invoiced_only')?.checked,
        selected: ids 
      }
    }).then(response => {
      if (response.error) {
        showErrorFlash(response.error.message);
      }
      else {
        let insertion_point = document.querySelector(container_selector);
        while (insertion_point.firstChild) {
          insertion_point.removeChild(insertion_point.firstChild);
        }
        for (let i = 0; i < response.data.length; ++i) {
          let curr = response.data[i];
          if (Object.prototype.hasOwnProperty.call(curr, 'type') && curr.type == 'header') {
            let to_insert = createElementFromString('<tr><td colspan="2">' + curr.name + '</td></tr>');
            insertion_point.appendChild(to_insert);
          }
          else {
            let to_insert = createElementFromString('<tr><td>' + curr.name + '</td><td>' + curr.value + '</td></tr>');
            insertion_point.appendChild(to_insert);
            //insertion_point.append('<tr><td>' + curr.name + '</td><td>' + curr.value + '</td</tr>');
          }
        }
      }
    }).catch(error => handleServerNetworkError(error))
      .finally(() => clearInterval(interval));
  };

  document.querySelector('#oms-statistics-button').addEventListener('click', event => {
    event.preventDefault();
    loadStats();
  });
}

// APPEND TEXT TO FORM
export function appendTextToFormField(to_append, form_selector) {
  document.querySelectorAll(form_selector).forEach(item => item.value = item.value + to_append);
}

/* PRIVATE FUNCTIONS */

function getQueryParameters() {
  let prmstr = window.location.search.substr(1);
  return prmstr != null && prmstr != '' ? transformToAssocArray(prmstr) : {};
}

function transformToAssocArray(prmstr) {
  let params = {};
  let prmarr = prmstr.split('&');
  for (let i = 0; i < prmarr.length; i++) {
    let tmparr = prmarr[i].split('=');
    params[tmparr[0]] = tmparr[1];
  }
  return params;
}
